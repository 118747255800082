import React, { Component } from 'react'
import axios from 'axios'
import { PaymentConfirmationSection } from './style'
import AuthContext from '../../context/auth-context'
import PurchaseLayout from '../Layout/Purchase'
import Check from '../../assets/images/general/check.jpg'

class NotAllowed extends Component<{}, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      reference: ''
    }
  }

  componentDidMount() {
    const { getUser } = this.context
    const customer = getUser()
    if (customer) {
      this.getPending(customer.customerId)
    }
  }

  getPending = (id: any) => {
    axios
      .post(`${process.env.API_URL}purchase/getpendings`, { id }, {})
      .then((data: any) => {
        if (!data.data.valid) {
          this.setState({ reference: data.data.reference })
        }
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  render() {
    return (
      <PurchaseLayout title="Transacción pendiente">
        <PaymentConfirmationSection>
          <div className="purchases-container box">
            <div>
              <img src={Check} />
            </div>
            <div className="success-lbl">No puedes continuar con esta compra</div>
            <div className="instructions-lbl">
              Tienes una transacción pendiente con número de referencia: {this.state.reference}.
              Pronto recibirás el estado de tu transacción al correo registrado en el momento de la
              compra.
            </div>
          </div>
        </PaymentConfirmationSection>
      </PurchaseLayout>
    )
  }
}
NotAllowed.contextType = AuthContext

export default NotAllowed
