import React from 'react'
import NotAllowed from '../components/NotAllowed'
import Layout from '../components/Layout'

export default () => (
  <Layout themeType="dark" currentPage={'purchase-not-allowed'}>
    <div>
      <NotAllowed />
    </div>
  </Layout>
)
